














































































































































import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { SaveTipoDeClienteUseCase } from '@/usecases'
import { TipoDeCliente } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {  obrigatorio } from '@/shareds/regras-de-form'
import { criarExpiraEm, criarTipoDeCliente, listasDeUnidadesDeTempo } from '@/shareds/cliente-shareds'

@Component({
	components: {
		CampoDecimal,
		BuscaDeProdutoDropdown,
	},
})
export default class DialogoDeEdicaoDePlano extends Vue {
	@PropSync('value', { type: Object }) planoAnterior?: TipoDeCliente
	@Ref() form!: HTMLFormElement
	saveTipoDeClienteUseCase = new SaveTipoDeClienteUseCase()
	plano: TipoDeCliente = criarTipoDeCliente()
	mostra = false
	salvando = false
	obrigatorio = obrigatorio

	listasDeUnidadesDeTempo = listasDeUnidadesDeTempo
	
	criarExpiraEm = criarExpiraEm

	mostrar(){
		this.mostra = true
	}

	@Watch('mostra')
	onChangeMostra(mostra) {
		if (!mostra) return
		if (this.planoAnterior) {
			this.plano = JSON.parse(JSON.stringify(this.planoAnterior))
		} else {
			this.plano = criarTipoDeCliente()
		}
	}
	
	async salvar(){
		if (!this.plano) return
		if (!this.form.validate()) return
		try {
			const salvarPlanos = !this.plano.id
				? await this.saveTipoDeClienteUseCase.create(this.plano)
				: await this.saveTipoDeClienteUseCase.update(this.plano)
			this.$emit('confirmado', salvarPlanos)
			AlertModule.setSuccess('Plano salvo com sucesso')
		} catch(error) {
			AlertModule.setError(error.response.data[0])
		} finally {
			this.mostra = false
			this.salvando = false
		}
	}
}

